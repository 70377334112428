<template>
  <!--
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/chat">Chat</router-link>
  </div> -->
  <router-view />
</template>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */
html {
  background-color: #2e3f4f;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.btn-grad {
  cursor: pointer;
  padding: 1rem 2rem;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 1rem;
  display: block;
  margin: auto;
  color: #fff;
  margin-bottom: 0.5rem;
  border: none;
}
.chatName {
  padding: 0.5rem 1rem;
}
.margin_top_1 {
  margin-top: 1rem;
}
.margin_top_2 {
  margin-top: 2rem;
}

.btn-grad_login {
  background-image: linear-gradient(
    to right,
    #02aab0 0%,
    #00cdac 51%,
    #02aab0 100%
  );
}
.interieur {
  margin: 1rem;
}
.getsize {
  margin-top: 1.2rem;
  margin-right: 0.5rem;
  text-align: right;
}
#login{
  padding-top: 3rem;
}
/* .container {
  position: absolute;
  background-color: rgba(33, 32, 56, 0.5);
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
} */

/* .interieur {
  margin: auto auto;
} */

.center {
  text-align: center;
}
.btn-min {
  padding: 0.5rem 1rem;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 0.5rem;
  display: block;
  margin: auto;
  color: #fff;
  border: none;
}
.btn-grad_regenere {
  background-image: linear-gradient(
    to right,
    #1d976c 0%,
    #93f9b9 51%,
    #1d976c 100%
  );
}
.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  text-decoration: none;
}

.epsila_color_fond {
  background-color: #10171e;
  color: #fff;
}
.epsila_color_card {
  background-color: #1d2935;
}
.epsila_color_menu {
  background-color: #1d2935;
}

.epsila_color_btn_valide {
  color: #fff;
  background: #42b983;
}
.epsila_color_btn_valide:hover {
  color: #fff;
  background: #42b983;
}
.epsila_color_btn_valide:disabled {
  color: #fff;
  background: #8f8f8f;
  cursor: default;
}
.epsila_color_btn_neutre {
  color: #fff;
  background: #2c3e50;
}
.epsila_color_btn_neutre:hover {
  color: #fff;
  background: #4e6e8e;
}

.epsila_color_btn_alert {
  color: #fff;
  background: #2c3e50;
}
.epsila_color_btn_alert:hover {
  color: #fff;
  background: #ca0548;
}
.epsila_green {
  color: #42b983;
}
.epsila_card {
  color: white;
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 4px 15px rgb(0 0 0 / 5%);
}
@media (min-width: 0em) and (max-width: 50em) {
  .interieur {
    margin: 0rem;
  }
}
.home {
  margin: auto;
  text-align: center;
  color: #42b983;
  padding: 5rem;
}
.userConnectInfo {
  color: #42b983;
  text-align: center;
  margin-bottom: 1em;
}
.userConnectInfo span{
  border-left: 1px solid;
  border-right: 1px solid;
  padding: 0em 1em 0em 1em;
  font-size: 1.4em;
}

/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-backdrop-filter: blur(15px); /* assure la compatibilité avec safari */
  backdrop-filter: blur(15px);
}

/* Modal Content/Box */
.modal-content {
  background-color: #1d2935;
  margin: 15% auto;
  padding: 20px;
  width: 80%;
  border-radius: 1rem;
}
.white-text {
  color: #fff;
}
.white {
  background-color: #fff;
}
.genNewId {
  color: #42b983;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
.btnDownload {
  color: #42b983;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
.btnDownload:hover {
  background-color: #42b983;
}
.btn_login {
  color: #fff;
  background-color: #42b983;
}
.btn_login:hover {
  background-color: #69c99e;
}
.btn_login:disabled {
  color: #fff;
  background: #8f8f8f;
  cursor: default;
}
.logoEpisila {
  margin: auto;
  width: 5rem;
  max-width: 16rem;
  display: block;
  margin-bottom: 2rem;
}
.epsila_texte {
  text-shadow: 0 0 0px #fff, 0 0 0px #fff, 0 0 15px #fff, 0 0 0px #42b983, 0 0 1px #42b983, 0 0 3px #42b983, 0 0 13px #42b983, 0 0 0px #42b983;
  color: #FFFFFF;
}
.verouille{
  font-size: 1.3em;
}
</style>
