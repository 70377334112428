import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "purecss/build/pure-min.css";
import "purecss/build/grids-responsive-min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import store from "./store";

createApp(App).use(store).use(router).mount("#app");
