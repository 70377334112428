import workerInterface from "@/service/workerInterface";

export default function createWebSocketPlugin(store) {
  store.registerModule("channels", {
    namespaced: true,
    state: {
      general: {
        id: "general",
        messages: [],
        name: "#Général",
        notif: 0,
        users: {},
      },
    },
    mutations: {
      setConnecter(state, channels) {
        state.general.users = channels;
        Object.keys(channels).reduce((accu, id) => {
          accu[id] = {
            id,
            messages: [],
            name: channels[id],
            notif: 0,
            users: {},
          };
          accu[id].users[id] = channels[id];
          return accu;
        }, state);
      },
      delConnecter(state, id) {
        delete state[id];
        delete state.general.users[id];
      },
      addConnecter(state, { pseudo, id }) {
        state[id] = {
          id,
          messages: [],
          name: pseudo,
          notif: 0,
          users: {},
        };
        state[id].users[id] = pseudo;
        state.general.users[id] = pseudo;
      },
      message(state, message) {
        state[message.idChannel].messages.push({
          name: state[message.idChannel].users[message.idUser],
          message: message.message,
          correspondant: true,
        });
      },
    },
  });
  workerInterface.addEventListener("addConnecter", (e) => {
    store.commit("channels/addConnecter", e.detail);
  });
  workerInterface.addEventListener("delConnecter", (e) => {
    store.commit("channels/delConnecter", e.detail);
  });

  workerInterface.addEventListener("setConnecter", (e) => {
    store.commit("channels/setConnecter", e.detail);
  });
  workerInterface.addEventListener("message", (e) => {
    store.commit("channels/message", e.detail);
  });
}
