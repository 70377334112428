import { createStore } from "vuex";
import channelInterface from "./channelInterface";
import workerInterface from "@/service/workerInterface";
import router from "@/router";

const store = createStore({
  state: {
    login: false,
    pseudo: "",
    channels: {},
  },
  getters: {},
  mutations: {
    setPseudo(state, pseudo) {
      state.pseudo = pseudo;
    },
    login(state, pseudo) {
      state.login = true;
      state.pseudo = pseudo;
    },
    logout(state) {
      state.login = false;
      state.pseudo = "";
      state.channels = {};
    },
  },
  actions: {},
  modules: {},
  plugins: [channelInterface],
});
workerInterface.addEventListener("connected", (e) => {
  console.log(e.detail.pseudo);
  if (e.detail.pseudo) {
    store.commit("setPseudo", e.detail.pseudo);
    router.push({ name: "Chat" });
  } else {
    router.push({ name: "CreateAccount" });
  }
});
export default store;
