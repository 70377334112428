const worker = new Worker(new URL("./worker.js", import.meta.url));

const myEventTarget = new EventTarget();

worker.addEventListener("message", function (e) {
  let event = new CustomEvent(e.data.event, { detail: e.data.data });

  myEventTarget.dispatchEvent(event);
});

worker.addEventListener("error", console.error);

worker.postMessage({ event: "test", data: {} });

myEventTarget.postMessage = (event, data) => {
  worker.postMessage({ event, data });
};

// myEventTarget.addEventListener("prompt", (e) => {
//   let pseudo;
//   while (!pseudo) {
//     pseudo = prompt(e.detail);
//   }
//   myEventTarget.postMessage("prompt", pseudo);
// });

export default myEventTarget;
